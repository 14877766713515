import { useNavigate } from "react-router-dom";
import './classLayout.css'
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ConfigData } from "../../Provider";
import axios from "axios";

export default function VideoViewer() {
    const {userData, api} = useContext(ConfigData)
  const [group, setGroup] = useState(0)
  const [finished, setFinished] = useState([])
  const {id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(`${api}user.php/${userData.id}`)
    .then(res => {
      setGroup(res.data.data.u_group)
      if(res.data.data.finished == null) {
        setFinished([])
      } else {
        setFinished(res.data.data.finished)
      }
    })

  }, [])
    
  return (
    <div id="ViewerPage">
        <div style={{width: '80%', height: '80vh'}} className="text-center">
            <video style={{width: '100%', height: '100%'}} src={`/video/${id}.mp4`} controls></video>
        </div>
        <div className="mt-3">
            <button onClick={(e) => {
                e.preventDefault()
                if(finished.includes(id)) {
                    navigate('/classroom')
                } else {
                    if(group == 1) {
                        if(localStorage.getItem(`userprog${id}`)) {
                            let prog = JSON.parse(localStorage.getItem(`userprog${id}`))
                            if(!prog.includes('video')) {
                                prog.push('video')
                            }
                            if(prog.length == 2) {
                                axios.get(`${api}user.php/${userData.id}/finished/${id}`)
                                .then((res) => navigate('/classroom'))
                                localStorage.removeItem(`userprog${id}`)
                            } else {      
                                localStorage.setItem(`userprog${id}`, JSON.stringify(prog))
                                navigate('/classroom')
                            }
                        } else {
                            localStorage.setItem(`userprog${id}`, JSON.stringify(['video']))
                            navigate('/classroom')
                        }
                    } else {
                        axios.get(`${api}user.php/${userData.id}/finished/${id}`)
                                .then((res) => navigate('/classroom'))
                    }
                }
                
            }} className="btn btn-dark">أنتهيت من المشاهدة</button>
        </div>
    </div>
  )
}
