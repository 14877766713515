import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ConfigData } from "../../Provider"

export default function G2({lessons, finished}) {
    const {userData, api} = useContext(ConfigData)
    const [final, setFinal] = useState(false)

    useEffect(() => {
        axios.get(`${api}exams.php/${userData.id}/finished`)
    .then(res => {
      setFinal(res.data.data)
    })
    })
  return (
    <div className="row px-3">
        {
            lessons.map(lesson => {
                return (
                    <div key={lesson.id} data-n={lesson.id} 
                            className={`text-center card p-0 mx-auto mb-3 col-12 col-md-4 ${finished.includes(lesson.id) ? 'active': ''}`}
                        >
                            <div className="card-title bg-dark text-light py-2">
                                {lesson.pre_title}
                            </div>
                            <div className="card-body">
                                <Link to={`/lesson/${lesson.id}`} className="btn btn-sm btn-dark">عرض المستوي</Link>
                            </div>
                    </div>
                )
            })
        }
        {
            final &&
                (
                    <div data-n='8' className="text-center card p-0 mx-auto mb-3 col-12 col-md-4 active">
                        <div className="card-title bg-dark text-light py-2">
                            الاختبار النهائي
                        </div>
                        <div className="card-body">
                            <Link to='/exam/8' className='btn btn-dark btn-sm'>إبدأ الإختبار</Link>
                        </div>
                    </div>
                )
        }
    </div>
  )
}
