import './leaders.css'
import researcherImg from '../../assets/images/researcher.jpg'

const Researcher = () => {
  return (
    <div className="leaders" id="researcher">
  <h2 className="main-title">عن الباحث</h2>
  <div className="container">
    <div className="box" style={{gridColumnStart: 2}}>
      <img src={researcherImg} alt="researcher" height={300} />
      <h3>م. حسام حمدي عبدالسلام محمد</h3>
      <div className="info">
        <p>معيد تكنولوجيا التعليم كلية التربية النوعية جامعة عين شمس</p>
      </div>
    </div>
  </div>
</div>

  )
}

export default Researcher