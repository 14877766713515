import { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { ConfigData } from "../../Provider";
import { useParams } from "react-router-dom";
import { EXAMS } from "../../Exams";
import AdminSingleExam from "./AdminSingleExam";

export default function AdminUser() {
    const [user, setUser] = useState([])
    const [exams, setExams] = useState([])
    const {api} = useContext(ConfigData)
    const [active, setActive] = useState(true)
    const {id} = useParams()
    

    const updateUser = (e, id, userD) => {
        e.preventDefault()
        axios.put(`${api}user.php/${id}`, JSON.stringify(userD), {
            headers: {
              'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
            }
        })
        .then(res=>setActive(!active))
    }


    useEffect(() => {
        axios.get(`${api}user.php/${id}`)
            .then(res => {
                setUser(res.data.data)
            })

            axios.get(`${api}exams.php/${id}/exams`)
            .then(res => {
                setExams(res.data.data)
            })
        
    }, [])

  return (
    <div>
        <Navbar page="admin" />
        <h2 className="text-primary text-center my-4">تعديل البيانات</h2>
        <form>
                <div className="form-outline mb-4 container">
                  <label className="form-label fw-bold" htmlFor="username">
                    اسم المستخدم
                  </label>
                  <input
                    type="text"
                    id="username"
                    className="form-control"
                    value={user.username}
                    disabled='disabled'
                  />
                </div>

                <div className="form-outline mb-4 container">
                  <label className="form-label fw-bold" htmlFor="password">
                    كلمة المرور
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={user.newPass ? user.newPass : ''}
                    onChange={(e) => setUser({...user, newPass: e.target.value})}
                  />
                </div>
         
                <div className="form-outline mb-4 container">
                  <label className="form-label fw-bold" htmlFor="fname">
                    اسم المستخدم بالكامل
                  </label>
                  <input
                    type="text"
                    id="fname"
                    className="form-control"
                    value={user.fname}
                    onChange={(e) => setUser({...user, fname: e.target.value})}
                  />
                </div>

                <div className="form-outline mb-4 container">
                  <label className="form-label fw-bold" htmlFor="email">
                    البريد الالكتروني
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control text-start"
                    value={user.email}
                    disabled='disabled'
                  />
                </div>

                <div className="form-outline mb-4 container">
                  <label className="form-label fw-bold" htmlFor="phone">
                    رقم الهاتف
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    value={user.phone}
                    disabled='disabled'
                  />
                </div>

                <div className="form-check mb-4 container">
                  <input
                    type="radio"
                    id="group1"
                    className="form-check-input"
                    value={1}
                    checked={user.u_group == 1}
                    onChange={() => setUser({...user, u_group: 1})}
                  />
                  <label className="form-label fw-bold" htmlFor="group1">
                    المجموعة الأولي
                  </label>
                </div>

                <div className="form-check mb-4 container">
                <input
                    type="radio"
                    id="group2"
                    className="form-check-input"
                    value={2}
                    checked={user.u_group == 2}
                    onChange={() => setUser({...user, u_group: 2})}
                  />
                  <label className="form-label fw-bold" htmlFor="group2">
                    المجموعة الثانية
                  </label>
                </div>

                <div className="mb-4 container">
                    <button className="btn btn-dark" onClick={(e) => updateUser(e, user.id, user)}>تعديل البيانات</button>
                </div>
        </form>

        <hr className="my-4" />

        <h2 className="text-primary text-center my-4">تعديل النقاط</h2>

        {
            exams.length &&
            (
                <div className="container">
                    {
                        exams.map(exam => {
                            let orginal = EXAMS[exam.exam_id - 1]
                            return (
                                <AdminSingleExam key={exam.exam_id} exam={exam} original={orginal} />
                                
                            )
                        })
                    }
                </div>
            )
        }
    </div>
  )
}
