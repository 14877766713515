import axios from "axios";
import Navbar from "../components/navbar/Navbar";
import { ConfigData } from "../Provider";
import { useContext, useEffect, useState } from "react";
import MyProgress from "../components/progress/MyProgress";
import { LESSONS } from "../LessonsData";
import ClassLayout from "../components/classroom/ClassLayout";
export default function Classroom() {
  const {userData, api} = useContext(ConfigData)
  const [group, setGroup] = useState(0)
  const [finished, setFinished] = useState([])

  useEffect(() => {
    axios.get(`${api}user.php/${userData.id}`)
    .then(res => {
      setGroup(res.data.data.u_group)
      if(res.data.data.finished == null) {
        setFinished([])
      } else {
        setFinished(res.data.data.finished)
      }
    })

  }, [])
  return (
    <div>
      <Navbar page="classroom">
        {
          group &&
          <MyProgress w={Math.round((finished.length/LESSONS.length) * 100)} />
        }
      </Navbar>
      {group ?
        <ClassLayout group={group} finished={finished} lessons={LESSONS} />
        :
        <h1 className="text-center mt-5 text-primary">في انتظار موافقه المسؤل</h1>
      }

    </div>
  )
}
