import './mypregress.css'

export default function MyProgress({w}) {
  return (
    <div className="my-progress">
      <span>مستوي التقدم {w}% </span>
      <span style={{'--w': `${w}%`}} />
    </div>
  );
}
