import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { ConfigData } from "../../Provider"
import badge1 from '../../assets/images/badge1.png'
import badge2 from '../../assets/images/badge2.png'
import badge3 from '../../assets/images/badge3.png'
import badge4 from '../../assets/images/badge4.png'
export default function ScoreTable({group}) {
  const {api} = useContext(ConfigData)
    const [points, setPoints] = useState([])
    
    useEffect(() => {
      axios.get(`${api}user.php/g${group}`)
    .then(res => {
      if(res.data.msg == 'success' && res.data.data.length) {
        setPoints(res.data.data)
      }
    })
    
    }, [])
  return (
    <div className="col-12 col-md-4">
          <table className="table table-striped text-center my-4 fw-bold">
            <thead>
                <tr>
                    <th scope="col">اسم الطالب</th>
                    <th scope="col">النقاط</th>
                    <th scope="col">الشارات</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {
                points.length ?
                  points.map((point, indx) => {
                    let badge = '';
                    if(+point.points >= 85) {
                      badge = badge1 
                    } else if(+point.points >= 75) {
                      badge = badge2
                    } else if(+point.points >= 65) {
                      badge = badge3
                    } else if(+point.points >= 50) {
                      badge = badge4
                    }
                    return (
                      <tr key={indx}>
                        <td>{point.fname}</td>
                        <td>{point.points}</td>
                        <td>
                          {
                            badge &&
                              <img src={badge} alt='badge' style={{width: '50px'}} />
                          }
                        </td>
                      </tr>
                    )
                  })
                      :
                      <tr>
                        <td colSpan={3}>لا توجد نقاط لأي طالب حتي الأن</td>
                      </tr>
              }
            </tbody>
          </table>
        </div>
  )
}
