import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { ConfigData } from "../../Provider"

export default function AdminSingleExam({exam, original}) {
    const [ans, setAns] = useState({})
    const {api} = useContext(ConfigData)
    const [totalPoint, setTotalPoint] = useState(0)

    const updateUserPoints = (e, data) => {
        e.preventDefault()
        axios.put(`${api}exams.php`, JSON.stringify(data), {
            headers: {
              'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
            }
        })
    }

    useEffect(() => {
        setAns(exam.ans)
    }, [])

    useEffect(() => {
        let t = 0
        for(let q in ans) {
            t += +ans[q].point
        }
        setTotalPoint(t)
    }, [ans])
  return (
    <div className="card my-4">
        <div className="card-header">
            <h3>{`الإختبار ${exam.exam_id}`} - إجمالي النقاط: {`${totalPoint}`}</h3>
        </div>
        <div className="card-body">
            {
                original.map(item => {
                    if(ans[`input${item.id}`]) {
                        return (
                            <div key={item.id}>
                                <p className="fw-bold">{item.type == 'select' ? item.q : item.title}</p>
                                <p><b>الإجابة :</b> {exam.ans[`input${item.id}`].ans}</p>
                                <input 
                                    type="text"
                                    className="form-control text-start w-50"
                                    value={ans[`input${item.id}`].point}
                                    onChange={(e) => {
                                        let n = {...ans}
                                        n[`input${item.id}`].point = e.target.value
                                        setAns(n)
                                    }}                  
                                />                        
                            </div>
                         )
                    }
                })
            }
        </div>

        <div className="my-4 container">
            <button onClick={(e) => {
                updateUserPoints(e, {user: exam.user_id, exam: exam.exam_id, ans: ans, points: totalPoint})
            }} className="btn btn-dark">حفظ التغييرات</button>
        </div>
    </div>
  )
}
