import './leaders.css'
import leader1 from '../../assets/images/leaders/1.jpg'
import leader2 from '../../assets/images/leaders/2.jpg'
import leader3 from '../../assets/images/leaders/3.png'

const Leaders = () => {
  return (
    <div className="leaders" id="leaders">
  <h2 className="main-title">الإشراف</h2>
  <div className="container">
    <div className="box">
      <img src={leader1} alt="leader 1" height={300} />
      <h3>أ.د/ هويدا سعيد عبدالحميد</h3>
      <div className="info">
        <p>
        أستاذ تكنولوجيا التعليم والتربية الخاصة ورئيس قسم تكنولوجيا التعليم كلية التربية النوعية - جامعة عين شمس
        </p>
      </div>
    </div>
    
    <div className="box">
      <img src={leader2} alt="leader 2" height={300} />
      <h3>أ.م.د/ زينب محمد العربي</h3>
      <div className="info">
        <p>
أستاذ تكنولوجيا التعليم المساعد كلية التربية النوعية - جامعة عين شمس</p>
      </div>
    </div>

    <div className="box">
      <img src={leader3} alt="leader 3" height={300} />
      <h3>د/ مروة محمد عبدالنبي</h3>
      <div className="info">
        <p>
مدرس تكنولوجيا التعليم كلية التربية النوعية - جامعة عين شمس </p>
      </div>
    </div>
  </div>
</div>

  )
}

export default Leaders