import { useContext, useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import axios from "axios";
import { ConfigData } from "../Provider";
import { LESSONS } from "../LessonsData";
import { Link, useParams } from 'react-router-dom';

export default function Lesson() {
    const {id} = useParams()
    const {userData, api} = useContext(ConfigData)
    const [msg, setMsg] = useState('')
    const [group, setGroup] = useState(0)

    useEffect(() => {
        axios.get(`${api}user.php/${userData.id}`)
        .then(res => {
          if(!res.data.data.u_group) {
            setMsg('في انتظار موافقه المسؤل')
          } else {
            setGroup(res.data.data.u_group)
          }
          if(res.data.data.finished == null) {
            if(id != 1 && res.data.data.u_group == 1) {
                setMsg('غير مسموح لك بعرض المستوي في الوقت الحالي')
            }
          } else {
            if(id != 1 && res.data.data.u_group == 1 && !(res.data.data.finished.includes(id)) && !(res.data.data.finished.includes(id - 1))) {
                setMsg('غير مسموح لك بعرض المستوي في الوقت الحالي')
            }
          }
        })
    
      }, [])
  return (
    <div>
      <Navbar page="classroom" />
      {msg &&
        <h1 className="text-center mt-5 text-primary">{msg}</h1>
      }
      {
        LESSONS.map(lesson => {
            if(lesson.id == id) {
                return (
                    <div className="container" key={lesson.id}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-center my-4 text-center">
                                    <div className="card col-12 mb-5 p-0" style={{width: '60%'}}>
                                        <div className="card-title bg-dark text-light py-2">
                                            عرض ملف PDF
                                        </div>
                                        <div className="card-body">
                                            <Link to={'/viewpdf/'+ lesson.id} className="btn btn-sm btn-dark">عرض الملف</Link>
                                            </div>
                                    </div>

                                    <div className="card col-12 mb-5 p-0" style={{width: '60%'}}>
                                        <div className="card-title bg-dark text-light py-2">
                                            عرض ملف فيديو
                                        </div>
                                        <div className="card-body">
                                            <Link to={'/viewvideo/'+ lesson.id} className="btn btn-sm btn-dark">عرض الملف</Link>
                                            </div>
                                    </div>

                                    <div className="card col-12 mb-5 p-0" style={{width: '60%'}}>
                                        <div className="card-title bg-dark text-light py-2">
                                            الاختبار
                                        </div>
                                        <div className="card-body">
                                            <Link to={'/exam/'+ lesson.id} className="btn btn-sm btn-dark">عرض الاختبار</Link>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
      }
    </div>
  )
}
