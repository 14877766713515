export default function Nopage() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{minHeight: "calc(100vh - 42px)"}}>
      <h1 className="text-center" style={{color: "var(--Tangerine)"}}>
        <span style={{fontSize: "7em"}}>404</span> <br />
        Page Not Found
        </h1>
    </div>
  )
}
