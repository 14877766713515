import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
//import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { ConfigData } from "./Provider";
import Nopage from "./pages/Nopage";
import Classroom from "./pages/Classroom";
import Lesson from "./pages/Lesson";
import PdfViewer from "./components/classroom/PdfViewer";
import VideoViewer from "./components/classroom/VideoViewer";
import ExamViewer from "./components/classroom/ExamViewer";
import Admin from "./pages/admin/Admin";
import AdminUser from "./pages/admin/AdminUser";
// import Logout from "./pages/Logout";
// import { Audio } from 'react-loader-spinner'
// import Landclassroom from "./components/classroom/Landclassroom";
// import Travel from "./components/classroom/Travel";
// import ClassLayout from "./pages/ClassLayout";

function App() {
  const {userData} = useContext(ConfigData);

  return (
    <div className="App">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            {!userData && <Route path="login" element={<Login />} />}
            {!userData && <Route path="register" element={<Login register={true} />} />}
            {userData && <Route path="classroom" element={<Classroom />} />}
            {userData && <Route path="lesson/:id" element={<Lesson />} />}
            {userData && <Route path="viewpdf/:id" element={<PdfViewer />} />}
            {userData && <Route path="viewvideo/:id" element={<VideoViewer />} />}
            {userData && <Route path="exam/:id" element={<ExamViewer />} />}
            <Route path='admin/hossam/2b4a25a6548cd5e94f5832e37d128e983bed3494' element={<Admin />} />
            <Route path='admin/hossam/2b4a25a6548cd5e94f5832e37d128e983bed3494/:id' element={<AdminUser />} />
            {/* <Route path="logout" element={ <Logout /> } />
            <Route path="classroom/" element={!isLogged? <Nopage /> : <ClassLayout />} >
              <Route index element={<Landclassroom />} />
              <Route path="lesson/:lesson" element={<Travel />} />
            </Route> */}
            <Route path="*" element={<Nopage />} />
          </Route>
        </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
