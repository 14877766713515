import { Link } from "react-router-dom";
import Img from '../../assets/images/header.jpg'
import './landing.css'
import { useContext } from "react";
import { ConfigData } from "../../Provider";

export default function Landing() {
  const {userData} = useContext(ConfigData)
  return (
    <div className="landing">
  <div className="container">
    <div className="text">
      <h1>مرحبًا بكم <span className="main-colored">Welcome</span></h1>
      <p>أهلا بكم طلاب تكنولوجيا التعليم</p>
      <div className="mt-4">
        <Link className="btn btn-primary" to={userData?'/classroom': 'login'}>
          {userData? 'حجرة الدراسة':'إضغط للدخول'}
        </Link>
      </div>
    </div>
    <div className="image">
      <img src={Img} alt="header" />
    </div>
  </div>
  <a href="#aboutcourse" className="go-down">
    <i className="fas fa-angle-double-down fa-2x" />
  </a>
</div>

  )
}
