import './classLayout.css'
import G1 from './G1';
import G2 from './G2';
import ScoreTable from './ScoreTable';
export default function ClassLayout({group, lessons, finished}) {
  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col-12 col-md-8">
            <div id='lessons' className="my-4">
                {
                    group === 1 ? <G1 lessons={lessons} finished={finished} /> : 
                    <G2 lessons={lessons} finished={finished} />
                }
            </div>
        </div>
        <ScoreTable group={group} />
      </div>
    </div>
  );
}
