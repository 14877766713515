import axios from 'axios';
import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const ConfigData = createContext();

export default function Provider(props) {
    const api = `https://gami-cataloge.com/api/`
    const [userData, setUserData] = useState(null)
    const [err, setErr] = useState('')
    const navigate = useNavigate()

    const login = ($cred) => {
      let data = {type: 'login', username: $cred.username, password: $cred.password, remember: $cred.remember, hashed: $cred.hashed}
      axios.post(`${api}user.php`, JSON.stringify(data), {
        headers: {
          'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
    }).then(res => {
      if(res.data.err) {
        setErr(res.data.msg)
        setUserData(null)
      } else {
        setUserData(res.data.data)
        setErr('')
        if(res.data.data.remember) {
          localStorage.setItem('user', JSON.stringify({username: res.data.data.username,
          password: res.data.data.password}))
        }
        navigate('/')
      }
    })

    }
    
    useEffect(()=> {
      if(!userData) {
        let user = localStorage.getItem('user');
        if(user){
          user = JSON.parse(user)
          if(typeof user.username !== 'undefined' && typeof user.password !== 'undefined') {
            login({username: user.username, password: user.password, remember: true, hashed: true})
          }
        }
      }
      
    }, []);
    

  return (
    <ConfigData.Provider value={{userData, err, login, setErr, api, setUserData}}>
      
        {props.children}
    </ConfigData.Provider>
  )
}
