export const EXAMS = [
    [
        {
            id: 1,
            type: 'select',
            q: '......... تعد مجموعة من القواعد التي يمكن بها وصف المواد التي تقتنيها المكتبة.',
            answers: ['الفهرسة', 'التصنيف', 'تكنولوجيا المعلومات', 'الببليوجرافية']
        },
        {
            id: 2,
            type: 'select',
            q: 'يساعد الفهرس في إعداد الفهارس الآتية ما عدا ........',
            answers: ['العامة', 'الفرعية', 'الموضوعية', 'الببليوجرافية']
        },
        {
            id: 3,
            type: 'select',
            q: '........ هو الكتاب الذي تجمع فيه الكتب.',
            answers: ['الفهرس', 'فهرس المؤلفين', 'الفهرس المصنف', 'الفهرس المجزأ']
        },
        {
            id: 4,
            type: 'select',
            q: 'يسهل الفهرس من مهمة أخصائي المكتبة أثناء عملية .........',
            answers: ['التصنيف', 'الاتصال', 'إعداد القوائم', 'الجرد']
        },
        {
            id: 5,
            type: 'select',
            q: 'يضم الفهرس المجزأ ............',
            answers: ['الفهرس المصنف', 'الفهرس المكشف', 'الفهرس الموضوعي', 'الفهرس القاموسي']
        },
        {
            id: 6,
            type: 'select',
            q: 'من مميزات فهرس العناوين ...........',
            answers: ['يجمع كل أعمال المؤلف الواحد في مكان واحد', 'سهل الاستعمال مقارنة مع الفهارس الأخرى', 'يفيد في الحصول على أوعية المعلومات التي تبحث في موضوع معين', 'تقديم جميع الاحتمالات للقارئ أو الباحث']
        },
        {
            id: 7,
            type: 'select',
            q: 'يعد ........ مخصص للاستشارة.',
            answers: ['الفهرس المصنف', 'الفهرس المجزأ', 'الفهرس الموضوعي', 'الفهرس القاموسي']
        },
        {
            id: 8,
            type: 'select',
            q: '.......... هي عملية إنتاج سجلات الفهارس المختلفة بمساعدة الحاسب الآلي.',
            answers: ['إنشاء مصادر المعلومات الإلكترونية', 'إنشاء القوائم المصنفة الإلكترونية', 'الفهرسة الآلية', 'القوائم الببليوجرافية الآلية']
        },
        {
            id: 9,
            type: 'select',
            q: 'من مميزات ........ أنها موفرة لوقت وجهد المفهرس والمستفيد.',
            answers: ['الفهرسة', 'الفهرسة الآلية', 'القائمة المصنفة', 'القائمة الببليوجرافية']
        },
        {
            id: 10,
            type: 'select',
            q: 'يعد الفهرس الآلي ........',
            answers: ['أكثر تشتتًا', 'أكثر بطاقات', 'أكثر صعوبة', 'أكثر حداثة ودقة']
        }
    ],
    [
        {
            id: 11,
            type: 'link',
            title: 'من خلال فهمك لخطوة تحليل بيانات المادة المفهرسة قم بالآتي:',
            points: ['إحضار 10 كتب من الكتب الموجودة على الانترنت.', 'تحليل بيانات الكتاب الأساسية والإضافية.', 'عمل جدول على برنامج word للبيانات الأساسية مع وضع غلاف الكتاب في خانة غلافة الكتاب.', 'عمل جدول على برنامج word للبيانات الإضافية.'],
            notice: '-	في حال عدم وجود أي بيان من البيانات المنصوص عليها يكتب في الخانة (لا يوجد).'
        },
        {
            id: 12,
            type: 'select',
            q: 'تعد مرحلة تحليل البيانات هي المرحلة ....... في إنشاء الفهرس الإلكتروني.',
            answers: ['الأولى', 'الثانية', 'الثالثة', 'الرابعة']
        },
        {
            id: 13,
            type: 'select',
            q: 'في مرحلة تحليل البيانات يتم تحديد .........',
            answers: ['حقول الجداول', 'الاستعلامات', 'نوع البيانات', 'البيانات الوصفية']
        },
        {
            id: 14,
            type: 'select',
            q: 'من البيانات الوصفية الإضافية .........',
            answers: ['مكان نشر العمل', 'نوع العمل', 'ملاحق العمل', 'رقم طبعة العمل']
        },
    ],
    [
        {
            id: 15,
            type: 'link',
            title: 'من خلال فهمك لخطوة تصميم قاعدة البيانات قم بالآتي:',
            points: ['تصميم قاعدة بيانات فارغة.', 'تصميم جدول البيانات الوصفية الأساسية مع تحديد نوع البيان داخل كل حقل من الحقول.', 'تصميم جدول البيانات الوصفية الإضافية مع تحديد نوع البيان داخل كل حقل من الحقول.', 'تحديد خصائص الحقول داخل كل جدول.', 'تصميم علاقة واحد إلى واحد بين الجدولين.', 'حفظ قاعدة البيانات.']
        },
        {
            id: 16,
            type: 'select',
            q: 'يجب أن تضم قاعدة البيانات ..........',
            answers: ['تحليل البيانات', 'تعريف حقول الجدول', 'سنة النشر', 'بيانات الطابعة']
        },
        {
            id: 17,
            type: 'select',
            q: 'في خطوة تصميم قاعدة البيانات يأتي تصميم الجداول بعد .....',
            answers: ['تعريف العلاقات', 'تحليل الاحتياجات', 'تصميم النماذج', 'إنشاء الاستعلامات']
        },
        {
            id: 18,
            type: 'select',
            q: 'من إجراءات إدارة البيانات .........',
            answers: ['استيراد البيانات', 'تعريف العلاقات', 'النسخ الاحتياطي', 'تحليل البيانات']
        },
        {
            id: 19,
            type: 'select',
            q: 'عند تصميم الجدول في برنامج أكسيس يجب تحديد .......',
            answers: ['العلاقة', 'الغرض', 'الاستعلام', 'نوع البيانات']
        },
        {
            id: 20,
            type: 'select',
            q: 'كل ما يلي من أنواع العلاقات في برنامج أكسيس ما عدا ......',
            answers: ['واحد إلى واحد', 'اثنين إلى اثنين', 'واحد إلى عديدة', 'عديدة إلى عديدة']
        },
    ],
    [
        {
            id: 21,
            type: 'link',
            title: 'من خلال فهمك لخطوة تصميم النموذج قم بالآتي:',
            points: [
                'تصميم نموذج لإضافة البيانات.', 
                'تصميم نموذج البحث.', 
                'تصميم نموذج فرعي لعرض النتائج.', 
                'إضافة خلفية للفهرس الخاص بك.',
                'إضافة زر إدخال / حذف البيانات.',
                'إضافة زر البحث.',
                'تنسيق النموذج مع مراعاة المعايير الفنية للتصميم المتفق عليها.',
                'إضافة عنوان للفهرس الخاص بك بما يتراءه مع الفهرس الخاص بك.'
            ]
        },
        {
            id: 22,
            type: 'select',
            q: 'يجب أن يتضمن النموذج ........',
            answers: ['الجداول التي تريدها', 'الحقول التي تريدها', 'الاستعلامات التي تريدها', 'البيانات التي تريدها']
        },
        {
            id: 23,
            type: 'select',
            q: 'من عناصر التحكم داخل برنامج أكسيس ......',
            answers: ['التقارير', 'الاستعلام', 'النماذج', 'القوائم المنسدلة']
        },
        {
            id: 24,
            type: 'select',
            q: 'يمكن في خطوة تخصيص النموذج ........',
            answers: ['تسجيل البيانات', 'فتح مساعد الاستعلام', 'استخدام نماذج الادخال', 'إضافة أزرار التحكم']
        },
        {
            id: 25,
            type: 'select',
            q: 'في خطوة ......... يمكن تغيير الخلفية أو الألوان.',
            answers: ['تخصيص النموذج', 'تصميم قاعدة البيانات', 'تصميم الجدول', 'الطباعة']
        },
        {
            id: 26,
            type: 'select',
            q: 'من المعايير الفنية للتنسيق ............',
            answers: ['عرض كميات معلومات كبيرة', 'ضغط المسافات بين السطور', 'استخدام نوع خط واحد', 'استخدام ألوان متناغمة']
        },
    ],
    [
        {
            id: 27,
            type: 'link',
            title: 'من خلال فهمك لخطوة إنشاء الاستعلامات قم بالآتي:',
            points: [
                'إنشاء استعلام لقاعدة البيانات المنشأة.', 
                'تحديد الجدول المطلوب للاستعلام.', 
                'تحديد حقول (اسم المؤلف – سنة النشر – رؤوس الموضوعات داخل الكتاب) للاستعلام.', 
                'تحديد معايير الاستعلام التي ترغب بها.',
                'حفظ الاستعلام.'
            ]
        },
        {
            id: 28,
            type: 'select',
            q: 'يتم إنشاء الاستعلامات لـ ........',
            answers: ['تحسين وظيفة النموذج', 'إضافة القوائم المنسدلة', 'البحث عن البيانات في الفهرس', 'تسجيل البيانات']
        },
        {
            id: 29,
            type: 'select',
            q: 'من خطوات إنشاء الاستعلام ..........',
            answers: ['تخصيص التنسيق', 'تحديد مصدر البيانات', 'تغيير ترتيب الحقول', 'تغيير حجم الحقول ']
        },
        {
            id: 30,
            type: 'select',
            q: 'يمكن تحديد الجداول المطلوبة من خلال النقر فوق الزر "إضافة الجدول" في شريط ......',
            answers: ['المهام', 'العنوان', 'القوائم', 'الأدوات']
        },
        {
            id: 31,
            type: 'select',
            q: 'تعد تحديد المعايير مرحلة من خطوة ........',
            answers: ['إنشاء الاستعلام', 'تصميم الجدول', 'الطباعة', 'تخصيص النموذج']
        },
    ],
    [
        {
            id: 32,
            type: 'link',
            title: 'من خلال فهمك لخطوة إدخال البيانات قم بالآتي:',
            points: [
                'إدخال البيانات الأساسية والإضافية المتحصل عليها من مرحلة تحليل البيانات المفهرسة إلى قاعدة البيانات.', 
                'تحرير البيانات وتعديلها من خلال جدول قاعدة البيانات.', 
                'إضافة صورة الغلاف للكتاب المفهرس إلى قاعدة البيانات.', 
                'في حالة وجود بيانات غير مرغوبة قم بحذف تلك البيانات.',
                'إدخال البيانات من خلال نموذج الإدخال المصمم.'
            ]
        },
        {
            id: 33,
            type: 'select',
            q: 'يمكن إدخال البيانات في قاعدة البيانات بعد .........',
            answers: ['استيراد البيانات', 'استخدام نماذج الادخال', 'تعريف العلاقات بين الجداول', 'تخصيص النموذج']
        },
        {
            id: 34,
            type: 'select',
            q: 'تستخدم استعلامات الادخال في ..........',
            answers: ['إدخال البيانات بشكل دفعي', 'الاستعلام داخل الجدول', 'إضافة سجل جديد', 'تحديث البيانات عند الحاجة']
        }
    ],
    [
        {
            id: 35,
            type: 'link',
            title: 'من خلال فهمك لخطوة إنشاء التقارير قم بالآتي:',
            points: [
                'إنشاء تقرير لقاعدة البيانات.', 
                'تحديد الجدول / الاستعلام المطلوب إنشاء تقرير له.', 
                'تنسيق التقرير بما يتناسب مع المعايير الفنية للتصميم.', 
                'ضبط حدود الصفحة للطباعة.',
                'حفظ التقرير.'
            ]
        },
        {
            id: 36,
            type: 'select',
            q: 'من الإعدادات التي يمكن ضبطها عند الطباعة ماعدا .....',
            answers: ['الحجم', 'التنسيق', 'الأدوات', 'التخطيط']
        },
        {
            id: 37,
            type: 'select',
            q: 'من خطوات الطباعة .........',
            answers: ['التحقق من الترتيب', 'التحقق من التنسيق', 'التحقق من العلاقات', 'التحقق من الأمان']
        },
        {
            id: 38,
            type: 'select',
            q: 'تعد مرحلة تخصيص الإعدادات من خطوة .........',
            answers: ['تخصيص النموذج', 'تصميم الجدول', 'الطباعة', 'إنشاء الاستعلام']
        },
        {
            id: 39,
            type: 'select',
            q: 'يمكن استخدام مساعد الطباعة في Access للحصول على ........',
            answers: ['الخيارات المتاحة للطابعة', 'تأكيد الطباعة', 'تحديد الطابع', 'فتح التقرير المراد طباعته']
        },
    ],
    [
        {
            id: 40,
            type: 'link',
            title: 'من خلال دراستك قم بالآتي:',
            points: [
                'عمل فهرس إلكتروني لعدد 10 كتب في مجال واحد مع تحديد ذلك من خلال عنوان الفهرس.'
            ]
        },
    ]
]