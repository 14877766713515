import { useNavigate } from "react-router-dom";
import './classLayout.css'
import { useParams } from "react-router-dom";
import { EXAMS } from "../../Exams";
import { useContext, useEffect, useState } from "react";
import { ConfigData } from "../../Provider";
import axios from "axios";

export default function ExamViewer() {
    const {userData, api} = useContext(ConfigData)
  const [finished, setFinished] = useState([])
  const {id} = useParams()
  const navigate = useNavigate()
  const [inputData, setInputData] = useState({})
  const [msg, setMsg] = useState('')
  const [end, setEnd] = useState(false)
  const exam = EXAMS[id-1]

  useEffect(() => {
    axios.get(`${api}user.php/${userData.id}`)
    .then(res => {
      if(res.data.data.finished == null) {
        setFinished([])
      } else {
        setFinished(res.data.data.finished)
      }
    })
    let data = {}
    exam.forEach(ex => {
        let k = `input${ex.id}`
        data[k] = ''
    })
    setInputData(data)

    axios.get(`${api}exams.php/${userData.id}/${id}`)
    .then(res => {
      if(res.data.data == true) {
        console.log(res.data.data)
        setEnd(true)
      }
    })
  }, [])
    
  return (
    
        (!end) ?
        (
            <form>
        <div className="exam-viewer container my-4 bg-light p-4 rounded">
        {
            (exam.length) && 
                exam.map((ex, i) => {
                    return (
                      <div key={ex.id} className="mb-5">
                        {i == 0 && ex.type == "link" ? (
                          <div>
                            <h2>{ex.title}</h2>
                            <ol>
                              {ex.points.map((point, indx) => (
                                <li key={indx}>{point}</li>
                              ))}
                            </ol>
                            {ex.notice ? (
                              <p>
                                <b> ملحوظة</b>
                                {ex.notice}
                              </p>
                            ) : (
                              ""
                            )}
                            <div className="mb-3">
                              <input
                                type="url"
                                className="form-control w-75 text-start"
                                placeholder="لينك الملف ..."
                                value={inputData[`input${ex.id}`]}
                                onChange={(e) => {
                                  let obj = { ...inputData };
                                  obj[`input${ex.id}`] = e.target.value;
                                  setInputData(obj);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {(i == 0 || i == 1) && (
                              <h2 className="mb-3">
                                اختر الإجابة الصحيحة من الاختيارات أسفل كل سؤال:
                              </h2>
                            )}
                            <h3 className="mb-4">{ex.q}</h3>
                            <div className="row">
                              {ex.answers.map((ans, inx) => (
                                <div key={inx} className="col-12 col-md-3">
                                  <div className="form-check">
                                    <input
                                        className="form-check-input mt-0"
                                        type="radio"
                                        value={ans}
                                        checked={inputData[`input${ex.id}`] === ans}
                                        onChange={(e) => {
                                            let obj = { ...inputData };
                                            obj[`input${ex.id}`] = e.target.value;
                                            setInputData(obj);
                                        }}
                                      />
                                      <label>
                                        {ans}
                                      </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                })
        }
        <div className="mt-3">
            <button onClick={(e) => {
                e.preventDefault()
                if(!finished.includes(+id) && id != 8) {
                    setMsg('لابد من الإنتهاء من الدراسة أولًا')
                } else {
                  for(let inputDataItem in inputData) {
                    inputData[inputDataItem] = {ans: inputData[inputDataItem], point: 0} 
                  }
                    let dataSend = {user_id: userData.id, exam_id: id, ans: inputData}
                    axios.post(`${api}exams.php`, JSON.stringify(dataSend), {
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => navigate('/classroom'))
                }
                
            }} className="btn btn-dark">إنهاء الاختبار</button>
        </div>
        {
            msg &&
                (
                    <p className="my-3">
                        {msg}
                    </p>
                )
        }
        </div>
    </form>
        ) :
        <h1 className="text-center mt-5 text-primary">لقد أنهيت هذا الاختبار</h1>
  )
}
