import { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { ConfigData } from "../../Provider";
import { Link } from "react-router-dom";

export default function Admin() {
    const [users, setUsers] = useState([])
    const {api} = useContext(ConfigData)
    const [active, setActive] = useState(true)

    const updateGroupUser = (id, groupVal) => {
        axios.put(`${api}user.php/${id}/group/${groupVal}`)
        .then(res=>setActive(!active))
    }

    const removeUser = (id) => {
        axios.delete(`${api}user.php/${id}`)
        .then(res=>setActive(!active))
    }

    useEffect(() => {
        axios.get(`${api}user.php`)
            .then(res => {
                setUsers(res.data.data)
            })
    }, [active])
  return (
    <div>
        <Navbar page="admin" />
        <table className="table container fw-bold table-striped text-center my-4">
            <thead>
                <tr>
                    <th scope="col">اسم الطالب</th>
                    <th scope="col">الأحداث</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {
                    users.length &&
                        users.map(user => (
                            <tr>
                                <td>{user.fname}</td>
                                <td>
                                    {
                                        (!user.u_group) ?
                                            <>
                                                <button className="btn btn-success me-2" onClick={() => updateGroupUser(user.id, 1)}>تفعيل مج 1</button>
                                                <button className="btn btn-success me-2" onClick={() => updateGroupUser(user.id, 2)}>تفعيل مج 2</button>
                                            </> :
                                        <span className="text-success me-2" style={{fontSize: ".8em"}}>تم التفعيل</span>
                                    }
                                    <Link to={`/admin/hossam/2b4a25a6548cd5e94f5832e37d128e983bed3494/${user.id}`} className="btn btn-primary me-2">تعديل</Link>
                                    <button className="btn btn-danger" onClick={() => removeUser(user.id)}>حذف</button>
                                </td>
                            </tr>
                        ))
                }
            </tbody>
        </table>
    </div>
  )
}
