import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ConfigData } from "../../Provider"

export default function G1({lessons, finished}) {
    const {userData, api} = useContext(ConfigData)
    const [final, setFinal] = useState(false)

    useEffect(() => {
        axios.get(`${api}exams.php/${userData.id}/finished`)
    .then(res => {
      setFinal(res.data.data)
    })
    })

  return (
    <>
        {
            lessons.map((lesson) => {
                return (
                    <div key={lesson.id} data-n={lesson.id} className={`card bg-light mx-auto mb-3 ${(finished.includes(lesson.id) || finished.includes(lesson.id - 1) || lesson.id === 1) ? 'active': ''}`} style={{width: "90%", opacity: (finished.includes(lesson.id) || finished.includes(lesson.id - 1) || lesson.id === 1)?'1':'0.6'}}>
                    <div className="card-body d-flex justify-content-between">
                        <h2 className='w-75' style={{fontWeight: "bold", fontSize: '1.2em'}}>{lesson.pre_title}</h2>
                        <div className='w-25 text-end'>
                            {
                                (finished.includes(lesson.id) || finished.includes(lesson.id - 1) || lesson.id === 1) &&
                                <Link to={`/lesson/${lesson.id}`} className='btn btn-dark btn-sm'>عرض المستوي</Link>
                            }
                        </div>
                    </div>
                </div>
                )
            })
        }
        {
            final && 
                (
                    <div data-n='8' className="card bg-light mx-auto mb-3 active" style={{width: "90%"}}>
            <div className="card-body d-flex justify-content-between">
                <h2 className='w-75' style={{fontWeight: "bold", fontSize: '1.2em'}}>الاختبار النهائي</h2>
                <div className='w-25 text-end'>
                    <Link to='/exam/8' className='btn btn-dark btn-sm'>إبدأ الإختبار</Link>
                </div>
            </div>
        </div>
                )
        }
    </>
  )
}
