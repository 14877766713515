import Navbar from '../components/navbar/Navbar';
import Landing from '../components/landing/Landing';
import AboutCourse from '../components/aboutCourse/AboutCourse';
import Leaders from '../components/leaders/Leaders';
import Researcher from '../components/leaders/researcher';
export default function Home() {
  return (
    <div className='App-home'>
      <Navbar />
      <Landing />
      <Researcher />
      <AboutCourse />
      <Leaders />
    </div>
  );
}
