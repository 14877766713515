import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfigData } from "../Provider";
import Navbar from "../components/navbar/Navbar";
import Img from '../assets/images/form.webp'
import axios from "axios";


export default function Login({register=false}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [fname, setFname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [remember, setRemember] = useState(false)
  const [validate, setValidate] = useState(null)
  const {userData, err, login, setErr, api} = useContext(ConfigData)
  const navigate = useNavigate()

  const changeHandle = (e, callback) => {
    if(e.target.type == 'checkbox') {
      callback(e.target.checked)
    }else {
      callback(e.target.value)
    }
    if(validate) {
      setValidate(null)
      setErr('')
    }
  }

  const registerHandle = (e) => {
    e.preventDefault()
    let data = {type: 'register', username: username, password: password, email: email, phone: phone, fname: fname, confirmPassword: confirmPassword}
    axios.post(`${api}user.php`, JSON.stringify(data), {
      headers: {
        'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
      }
  }).then(res => {
    if(res.data.err) {
      setErr(res.data.msg)
    } else {
      navigate('/login')
    }
  })

  }

  const handleSubmit = (e, type) => {
    e.preventDefault()
    if(type === 'login') {
      login({
        username: username, password: password, remember: remember, hashed: false
      })
    }
  } 

  useEffect(() => {
    if(err) {
      setValidate(err)
    }
  }, [err, userData])


  return (
    <>
      <Navbar page="form" />
      <section className="my-5">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              {err && <div className="alert alert-danger" role="alert">{err}</div>}
              <form>
                {/* Email input */}
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="username">
                    اسم المستخدم
                  </label>
                  <input
                    type="text"
                    id="username"
                    className="form-control"
                    placeholder="ادخل اسم المستخدم الخاص بك"
                    value={username}
                    onChange={(e) => changeHandle(e, setUsername)}
                  />
                </div>
                {/* Password input */}
                <div className="form-outline mb-3">
                  <label className="form-label" htmlFor="password">
                    كلمة المرور
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="ادخل كلمة المرور"
                    value={password}
                    onChange={(e) => changeHandle(e, setPassword)}
                  />
                </div>
                {register && (
                  <>
                    <div className="form-outline mb-3">
                      <label className="form-label" htmlFor="confirmPassword">
                        تأكيد كلمة المرور
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        className="form-control"
                        placeholder="ادخل كلمة المرور مره أخري"
                        value={confirmPassword}
                        onChange={(e) => changeHandle(e, setConfirmPassword)}
                      />
                    </div>

                    <div>
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="fullName">
                          الاسم بالكامل
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          className="form-control form-control"
                          placeholder="ادخل اسمك بالكامل"
                          value={fname}
                          onChange={(e) => changeHandle(e, setFname)}
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="email">
                          البريد الإلكتروني
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control"
                          placeholder="ادخل بريدك الإلكتروني"
                          value={email}
                          onChange={(e) => changeHandle(e, setEmail)}
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="phone">
                          رقم الهاتف
                        </label>
                        <input
                          type="text"
                          id="phone"
                          className="form-control form-control"
                          placeholder="ادخل رقم الهاتف"
                          value={phone}
                          onChange={(e) => changeHandle(e, setPhone)}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center">
                  {!register && (
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        checked={remember}
                        id="remember"
                        onChange={(e) => changeHandle(e, setRemember)}
                      />
                      <label className="form-check-label" htmlFor="remember">
                        تذكرني
                      </label>
                    </div>
                  )}
                </div>
                {!register ? (
                  <div className="text-center text-lg-start mt-4 pt-2">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                      onClick={(e) => handleSubmit(e, 'login')}
                    >
                      دخول
                    </button>
                    <p className="small fw-bold mt-2 pt-1 mb-0">
                      ليس لديك حساب؟{" "}
                      <Link to={'/register'} className="link-danger">
                        تسجيل
                      </Link>
                    </p>
                  </div>
                ) : (
                  <div className="text-center text-lg-start mt-4 pt-2">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                      onClick={registerHandle}
                    >
                      تسجيل
                    </button>
                    <p className="small fw-bold mt-2 pt-1 mb-0">
                      {" "}
                      لديك حساب بالفعل؟{" "}
                      <Link to={'/login'} className="link-danger">
                        دخول
                      </Link>
                    </p>
                  </div>
                )}
              </form>
            </div>
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img src={Img} className="img-fluid" alt="Sample_image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
