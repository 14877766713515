export const LESSONS = [
    {
        id: 1,
        pre_title: 'المستوي الأول',
        pdf: 'https://drive.google.com/file/d/1F7QtT0-H67Dxbi0GFt5TqyWf4PgxekRm/preview'
    },
    {
        id: 2,
        pre_title: 'المستوي الثاني',
        pdf: 'https://drive.google.com/file/d/1xCuPqlYcv2O7OM6dyKbXZDH4dxjH8j-S/preview',
    },
    {
        id: 3,
        pre_title: 'المستوي الثالث',
        pdf: 'https://drive.google.com/file/d/1is0HWAPpm2Nn8fK4H9_9JyAZzm32R73D/preview',
    },
    {
        id: 4,
        pre_title: 'المستوي الرابع',
        pdf: 'https://drive.google.com/file/d/1eRagOEyET2cSYLnsD-kZJ4e9lieEQ06b/preview',
    },
    {
        id: 5,
        pre_title: 'المستوي الخامس',
        pdf: 'https://drive.google.com/file/d/1dLzNGyUUujVe9jLSsMs0r3jhMhEafwM7/preview',
    },
    {
        id: 6,
        pre_title: 'المستوي السادس',
        pdf: 'https://drive.google.com/file/d/1YknNcrdu9i2JVPBkYPp2x9fM8s_iLy8s/preview',
    },
    {
        id: 7,
        pre_title: 'المستوي السابع',
        pdf: 'https://drive.google.com/file/d/1HNM8eme89Qf03xwG9fDHiJvttJX8NuL4/preview',
    },
]