import imag from "../../assets/images/header.jpg";
import "./aboutCourse.css";

const AboutCourse = () => {
  return (
    <div className="aboutcourse" id="aboutcourse">
      <h2 className="main-title">عن الدورة</h2>
      <div className="container">
        <div className="holder">
          <div className="list">
            <div className="name">
              عن الدورة
              <i className="fas fa-book" />
            </div>
            <ul
              className="nav nav-pills flex-column"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <li>
                <button
                  className="nav-link active"
                  id="v-pills-targets-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-targets"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-targets"
                  aria-selected="true"
                >
                  أهداف البرنامج
                </button>
              </li>
              <li>
                <button
                  className="nav-link"
                  id="v-pills-tools-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-tools"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-tools"
                  aria-selected="false"
                >
                  الوسائط المستخدمة
                </button>
              </li>
              <li>
                <button
                  className="nav-link"
                  id="v-pills-games-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-games"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-games"
                  aria-selected="false"
                >
                  محفزات الألعاب
                </button>
              </li>

              <li>
                <button
                  className="nav-link"
                  id="v-pills-levels-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-levels"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-levels"
                  aria-selected="false"
                >
                  المستويات
                </button>
              </li>

              <li>
                <button
                  className="nav-link"
                  id="v-pills-exams-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-exams"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-exams"
                  aria-selected="false"
                >
                  التقييم
                </button>
              </li>
              
            </ul>
          </div>
          <div className="preview tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-targets"
              role="tabpanel"
              aria-labelledby="v-pills-targets-tab"
            >
                <h2>هدف البرنامج العام</h2>
                <p>
                -	إنشاء فهرس إلكتروني
                </p>
                <h2>الأهداف الإجرائية للبرنامج</h2>
                <p>
                يهدف البرنامج الحالي إلى أن يكون المتعلم قادرًا على أن:
<br />1-	يتعرف على ماهية الفهرسة.
<br />2-	يحلل بيانات المادة المفهرسة.
<br />3-	يصمم قاعدة بيانات باستخدام برنامج access.
<br />4-	يصمم نموذج البحث والإضافة.
<br />5-	ينسق نموذج البحث والإضافة.
<br />6-	يدخل البيانات إلى قاعدة البيانات.
<br />7-	يصمم الاستعلامات الخاصة بقاعدة البيانات.
<br />8-	يصمم التقرير الخاص بقاعدة البيانات.

                </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-tools"
              role="tabpanel"
              aria-labelledby="v-pills-tools-tab"
            >
            <h2>الوسائط المستخدمة</h2>
            <p>
            يعتمد البرنامج الحالي على مجموعة من الوسائط المتعددة التي تساعد المتعلم على القيام بإنتاج الفهرس الإلكتروني باستخدام برنامج access وهي:
            </p>
            <p>
            <b>1-	الفيديو: </b>
يهدف الفيديو إلى توصيل المعلومة العملية للمتعلمين عن طريق تعريفهم خطوات الإنتاج ومن ثم يقوم المتعلم بتطبيق ما رآه بالفيديو.
            </p>
            <p>
            <b>2-	المستند: </b>
يهدف المستند إلى توصيل معلومات نظرية عن الخطوات الموجودة بالفيديو والتي تخدم المتعلم أثناء القيام بتنفيذ التجربة.

            </p>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-games"
              role="tabpanel"
              aria-labelledby="v-pills-games-tab"
            >
            <h2>محفزات الألعاب</h2>
            <p>
            يستخدم البرنامج الحالي محفزات الألعاب كجزء أساسي من التعامل داخل البرنامج بهدف تحفيز المتعلمين ومنها:
            </p>
            <p>
            <b>1-	لوحة المتصدرين:  </b>
            تهدف لوحة المتصدرين إلى تعريف المتعلم بترتيبه بين زملاؤه.
            </p>
            <p>
            <b>2-	النقاط:  </b>
            تعطى النقاط لكل متعلم على أساس تفاعله داخل البرنامج وما وصل إليه داخل كل مستوى ثم تجمع النقاط لتوضع داخل لوحة المتصدرين.

            </p>
            <p>
            <b>3-	الشارات: </b>
            تنقسم الشارات إلى:
            <br />
            -	شارة ذهبية.
            <br />
            -	شارة فضية.
            <br />
            -	شارة برونزية.
            <br />
            -	شارة فارغة.
            </p>
            <p>
            <b>4-	شريط التقدم: </b>
            يعطي شريط التقدم للمتعلم معلومات عن مدى تقدمه في البرنامج كاملًا وما وصل إليه بالنسبة المئوية.
            </p>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-levels"
              role="tabpanel"
              aria-labelledby="v-pills-levels-tab"
            >
            <h2>المستويات</h2>
            <p>
            يحتوي كل مستوى داخل البرنامج على 3 أدوات وهم الفيديو التعليمي والمستند وكذلك أداة المطلوب.
            <br />
            -	يجب على المتعلم في النمط المقيد الاطلاع المستوى الأول ثم الثاني ثم الذي يليه في شكل يشبه الصعود بالسلم ويجب عليه أيضًا الاطلاع على الأدوات الثلاث الموجودة داخل كل مستوى حتى يتم الانتهاء منه. 
            <br />
            -	يجب على المتعلم في النمط الحر الاطلاع على المستويات جميعًا مع عدم وجود فرض لترتيب المستويات عليه ويمكنه فقط مشاهدة الفيديو أو المستند مع المطلوب حتى يتمكن من تخطي المستوى للتوجه إلى المستوى الأخر.   
            </p>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-exams"
              role="tabpanel"
              aria-labelledby="v-pills-exams-tab"
            >
            <h2>التقييم</h2>
            <p>
            <b>النقاط: </b>
            يتم احتساب النقاط على أساس تقييم أعضاء التقييم للنشاط المطلوب بعد كل مستوى.
            </p>
            <p>
            <b>الشارات: </b>
            <br />
            -	شارة ذهبية في حالة تخطي عدد نقاط 85 نقطة للمستوى.
            <br />
            -	شارة فضية في حالة تخطي عدد نقاط 75 نقطة للمستوى.
            <br />
            -	شارة برونزية في حالة تخطي عدد نقاط 65 نقطة للمستوى.
            <br />
            -	شارة فارغة في حالة تخطي عدد نقاط 50 نقطة للمستوى.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCourse;