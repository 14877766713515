import { Link, useNavigate } from "react-router-dom";
import './navbar.css'
import { ConfigData } from "../../Provider";
import { useContext } from "react";
import { HashLink } from 'react-router-hash-link';


export default function Navbar({page = 'home', children}) {
  const {setUserData} = useContext(ConfigData)
  const navigate = useNavigate()
  return (
    <div className={`header ${page==='classroom'? 'bg-dark':''}`} id="header">
  <div className={`container ${page === 'form' ? 'justify-content-center':''}`}>
    <Link to={'/'} className="logo">الفهرس الإلكتروني</Link>
    {
      page === 'home' &&
      (<ul className="main-nav">
      <li><HashLink to="#researcher">عن الباحث</HashLink></li>
      <li><HashLink to="#aboutcourse">عن الدورة</HashLink></li>
      <li><HashLink to="#leaders">الإشراف</HashLink></li>
    </ul>)
    }
    {
      page === 'classroom' &&
      <>
      {children}
      <button className="btn btn-danger"
      onClick={()=> {
        setUserData(null)
        localStorage.removeItem('user')
        navigate('/')
      }}>تسجيل خروج</button>
      </>
    }
  </div>
</div>

  );
}
